import { css } from "styled-components";
import { breakpoints, ColorDark } from "@/shared/Constants";


export const lineGridAfter=css`
 &:after {
    background-color: #fff;
      ${breakpoints.phone} {
          background-color: #E0E0E0;
    }
       ${breakpoints.darkMode} {
        [data-theme='dark'] & {
      border-bottom:1px solid ${ColorDark.greySeparator};
      background-color:transparent;
        }
    }
  }
`



export const Modulo4PisosGrid = css`
  margin-top: 20px;
  gap: 20px;
  margin: auto;
  width: max-content;
  display: grid;
  grid-template-columns: repeat(4, 314px);
  padding-top: 1rem;

  .box1 {
    display: grid;
    grid-template-columns: repeat(2, 314px);
    gap: 20px;
    grid-area: 1 / 1 / 11 / 3;
    grid-row: 1;
    .div1 {
      grid-area: 1 / 1 / 3 / 2;
      ${lineGridAfter}
    }
    .div2 {
      grid-area: 1 / 2 / 3 / 3;
      ${lineGridAfter}
    }
    .div3 {
      grid-area: 3 / 1 / 5 / 3;
      padding: 1rem 0;
      border-top: 1px solid #e0e0e0;
      ${breakpoints.phone} {
        border-top:0 none;
      }
    }
    .div4 {
      grid-area: 5 / 1 / 7 / 2;
  
    }
    .div5 {
      grid-area: 5 / 2 / 6 / 3;
    }
    .div6 {
      grid-area: 6 / 2 / 7 / 3;
      &:after{
          width: 110%;
          left: -37px;
          ${breakpoints.phone}{
            width:100%;
          }
        }
    }
    .div7 {
      grid-area: 7 / 1 / 9 / 2;
      max-height:316px;
      overflow:hidden;
      ${breakpoints.phone}{
         
          max-height:inherit;
        }
      &::after {
        width: 113%;
        ${breakpoints.phone}{
          width:100%;
          max-height:inherit;
        }
      }
    }
    .div8 {
      grid-area: 7 / 2 / 9 / 3;
    }
    .div9 {
      grid-area: 9 / 1 / 11 / 2;
      ${lineGridAfter}
    }
    .div10 {
      grid-area: 9 / 2 / 11 / 3;
      ${lineGridAfter}
    }
    ${breakpoints.phone} {
      display: flex;
      flex-direction: column;
    }
  }
  .box2 {
    grid-area: 1 / 3 / 11 / 4;
    grid-row: 1;
    display:flex;
    justify-content:center;
    ${lineGridAfter}
    ${breakpoints.phone}{
        > div{
          margin-bottom:30px;
        }
    }
  }
  .box3 {
    grid-area: 1 / 4 / 11 / 5;
    gap: 20px;
    display: grid;
    grid-row: 1;
    ${breakpoints.tablet} {
      flex-direction: row;
      justify-content: space-between;
      .div11 {
        grid-area: 1 / 1 / 5 / 2;
      }
      .div12 {
        grid-area: 1 / 2 / 3 / 3;
      }
      .div13 {
        grid-area: 3 / 2 / 5 / 3;
      }
      .div14 {
        grid-area: 1 / 3 / 3 / 4;
      }
      .div15 {
        grid-area: 3 / 3 / 5 / 4;
      }
    }
    ${breakpoints.phone}{
        margin-top:15px;
    }
  }

  ${breakpoints.tablet} {
    grid-template-columns: repeat(3, 314px);
    row-gap: 5px;
    .box1 {
      grid-area: 1 / 1 / 9 / 3;
      height: 100%;
    }
    .box2 {
      grid-area: 1 / 3 / 9 / 4;
      height: 100%;
    }
    .box3 {
      grid-area: 9 / 1 / 11 / 4;
      .div14{
        grid-area: 1 / 3 / 5 / 4;
        ${lineGridAfter}
        > div {
          position: sticky;
          top: 50px;
          height: 100%;
        }
      }
    }
  }
  ${breakpoints.phone} {
    display: flex;
    flex-direction: column;
  }
`;
